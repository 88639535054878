/* ================================================
|   |   |   |   Clients
================================================ */
#portfolio .vertical-heading {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  #portfolio .vertical-heading h5 {
    top: 70px;
  }
  
  #portfolio .vertical-heading h2 {
    padding-bottom: 30px;
  }
  
  #portfolio-2 .vertical-heading {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  #portfolio-2 .vertical-heading h5 {
    top: 70px;
  }
  
  #portfolio-2 .vertical-heading h2 {
    padding-bottom: 30px;
  }
  .row.no-gutters [class*=col-] {
    padding: 4px;
    background-color: #fff;
  }
  
  .portfolio-item {
    // overflow: hidden;
    position: relative;
  }

  .portfolio-item-2 {
    // overflow: hidden;
    position: relative;
    border: 2px solid #000;
  }
  
  #services .brand-thin {
    font-weight: 100;
    font-size: 35px !important;
    margin-bottom: 30px;
  }
  
  /* Filter CSS */
  .client-border {
    border: 1px solid #a1a0a1;
  }
  
  .client-border-2 {
    border: 1px solid #a1a0a1;
    /*background-color: #dcdcdc;*/
  }
  
  #isotope-filters {
    /*margin-bottom: 5px;*/
    margin-top: -62px;
    padding: 20px 0 20px 0;
    text-align: center;
  }
  
  #isotope-filters button {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    /*background: transparent;*/
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
  }
  
  #isotope-filters button span {
    display: block;
    -webkit-transition: border-color .4s ease-in-out;
    transition: border-color .4s ease-in-out;
  }
  
  #isotope-filters button.active span {
    color: #4dd0e1;
    border-bottom: 3px solid #4dd0e1;
  }
  
  
  /* Portfolio filters */
  
  #isotope-filters-2 {
    /*margin-bottom: 5px;*/
    margin-top: -62px;
    padding: 20px 0 20px 0;
    text-align: center;
  }
  
  #isotope-filters-2 button {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    /*background: transparent;*/
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
  }
  
  #isotope-filters-2 button span {
    display: block;
    -webkit-transition: border-color .4s ease-in-out;
    transition: border-color .4s ease-in-out;
  }
  
  #isotope-filters-2 button.active span {
    color: #4dd0e1;
    border-bottom: 3px solid #4dd0e1;
  }
  
  
  
  
  .client-custom {
    display: inline-block;
  }
  
  
  
  
  /* Zoom Effect */
  .portfolio-item img {
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;

    // height: 378px;
    // width: 100%;
    /*transition: transform .5s ease, -webkit-transform .5s ease ;*/
  }

  .portfolio-item-2 img {
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;

    // height: 378px;
    // width: 100%;
    /*transition: transform .5s ease, -webkit-transform .5s ease ;*/
  }
  
//   .portfolio-item:hover img {
//     -webkit-transform: scale(1.1);
//     transform: scale(1.1);
//   }
  
  /* Overlay Effect */
  .portfolio-item-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(14, 14, 14, 0.85);
    cursor: zoom-in;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .portfolio-item-overlay-2 {
    position: absolute;
    top: 65%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(14, 14, 14, 0.85);
    cursor: zoom-in;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  /* Item Details Effect*/
  .portfolio-item-details {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;

    width: 80%;
    top: 50%;
    left: 10%;


    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .portfolio-item-details h3 {
    color: #4dd0e1;
    color: #9fd399;
    text-transform: uppercase;
    /*font-size: 30px;*/
    font-size: 23px;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-weight: 800;
  }
  
  .portfolio-item-details span {
    display: inline-block;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    height: 6px;
    width: 30px;
    margin: 11px auto 5px auto;
  }
  
  .portfolio-item-details p {
    /*font-size: 12px;*/
    font-size: 10px;
    color: #dcdcdc;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    line-height: 22px;
    width: 85%;
  }
  
  /* Show Overlay on mouse over */
  .portfolio-item:hover .portfolio-item-details h3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .portfolio-item:hover .portfolio-item-details p {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  .portfolio-item:hover .portfolio-item-overlay {
    opacity: 1;
  }




  .portfolio-item-2:hover .portfolio-item-details h3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .portfolio-item-2:hover .portfolio-item-details p {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .portfolio-item-2 .portfolio-item-details h3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .portfolio-item-2 .portfolio-item-details p {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  
  // .portfolio-item-2:hover .portfolio-item-overlay {
  //   opacity: 1;
  // }

  .portfolio-item-2 .portfolio-item-overlay {
    opacity: 1;
  }

  .portfolio-item-2:hover .portfolio-item-overlay-2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(14, 14, 14, 0.85);
    cursor: zoom-in;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .portfolio-item-2:hover .portfolio-item-overlay-2 {
    opacity: 1;
  }

  .portfolio-item-2 .portfolio-item-overlay-2 {
    opacity: 1;
  }



  #lightbox {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*optional bg color*/
    background-color: rgba(0, 0, 0, 0.82);
    /*the following isn't necessary but will help center everything once we add buttons*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#lightbox-img {
    height: 80vh;
    max-width: 80vw;
    object-fit: cover;

    @media screen and (max-width: 700px) {
        height: 60vh;
        max-width: 70vw;
    }

    @media screen and (max-width: 550px) {
        height: 50vh;
        max-width: 70vw;
    }

    @media screen and (max-width: 450px) {
        height: 50vh;
        max-width: 80vw;
    }

    @media screen and (max-width: 350px) {
        height: 50vh;
        max-width: 80vw;
    }
}

.lightbox-button {
    color: #9fd399;
    border: 2px solid #9fd399;
    background-color: black;
    font-size: 2rem;
    cursor: pointer;

    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 700px) {
        padding-left: 2.5px;
        padding-right: 2.5px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media screen and (max-width: 450px) {
        padding-left: 2px;
        padding-right: 2px;
        margin: 5px;
        font-size: 1.1rem;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    @media screen and (max-width: 350px) {
        padding-left: 2px;
        padding-right: 2px;
        margin: 2px;
        font-size: 1rem;
    }
}

// .break {
//   flex-basis: 100%;
//   height: 0;
// }



.lightboxbutton {
  position: absolute;
  z-index: 1;
  right: 20px;
}

// .lightboximg {
//   position: relative;
// }

.lightboxbutton span {
  color: red; // for testing purpose
}

.lightboxclose {
  color: #9fd399;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
  background-color: #000;
  border: 2px solid #9fd399;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 25px 3px;
  border-radius: 50%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
  padding-top: 7px;
}