:root {
    --font-base: "DM Sans", sans-serif;
  
    --primary-color: #edf2f8;
    --secondary-color: #313bac;
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
  }
  
  .app__navbar {
    width: 100%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 1rem 2rem;
  
    -webkit-backdrop-filter: blur(4px);
  
    position: fixed;
    z-index: 2;
  }
  
  .app__navbar-scroll {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(4px);
    //border: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: .5px solid #000;
    z-index: 1000;
  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
    img {
      //width: 90px;
      //height: 20px ;
      width: 150px;
      //width: 176px;
  
      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
      }
    }
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-right: 160px;

  
    li {
      margin: 0 1rem;
      cursor: pointer;
  
      flex-direction: column;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
  
        margin-bottom: 5px;
      }

      .navbar__links-styles {
        text-decoration: none;
        flex-direction: column;
        // text-transform: uppercase;
        text-transform: capitalize;
        font-weight: 500;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
        color: #9fd399;
        }
      }

      .navbar__links-styles-scroll {
        text-decoration: none;
        flex-direction: column;
        // text-transform: uppercase;
        text-transform: capitalize;
        font-weight: 500;
  
        transition: all 0.3s ease-in-out;
  
        &:hover {
        color: #9fd399;
        }
      }


  
      &:hover {
        div {
        //   background-color: var(--secondary-color)
                //background-color: var(--secondary-color)
                margin-left: auto;
                margin-right: auto;
                // margin-bottom: 4px;
                color: #9fd399;
        }
      }
    }
  
    @media screen and (max-width: 1160px) {
      display: none;
    }
  }


  .navbar__links-color {
    color: #fff;

    &:hover {
        //color:var(--secondary-color);
        color: #9fd399;
      }
    
      &:target {
        color: #9fd399;
      }
    
      &:focus {
        color: #9fd399;
      }
    
      &:active {
        //color:var(--secondary-color)
        color: #9fd399;
        //text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
        //1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
      }
  }
  
  .navbar__links-color-scroll {
    color: #000;

    &:hover {
        //color:var(--secondary-color);
        color: #4dd0e1;
      }
    
      &:target {
        color: #4dd0e1;
      }
    
      &:focus {
        color: #4dd0e1;
      }
    
      &:active {
        //color:var(--secondary-color)
        color: #4dd0e1;
        //text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
        //1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
      }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
 //background-color: var(--secondary-color);
    background-color: #9fd399;
  
    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }

    .app__navbar-menu-scroll {
      background-color: #4dd0e1; 
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
  
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
  
      //background: url('../../assets/bgWhite.png');
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
  
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 48px;
        color: #213805;
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;

          .app__navbar-menu-links {
            //color: var(--gray-color);
            color: #213805;
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
  
          }
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }

          &:hover {
            //color:var(--secondary-color);
            color: #9fd399;
          }
  
          &:target {
            color: #9fd399;
          }
  
          &:focus {
            color: #9fd399;
          }
  
          &:active {
            //color:var(--secondary-color)
            color: #9fd399;
            //text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
            //1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
          }
        }
  
        @media screen and (min-width: 1160px) {
          display: none;
        }
      }
  
      @media screen and (min-width: 1160px) {
        display: none;
      }
    }
    @media screen and (min-width: 1160px) {
      display: none;
    }
  }





  .app__navbar-menu-scroll {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
 //background-color: var(--secondary-color);
    background-color: #9fd399;
    transition: all 0.3s ease-in-out;
    border: 1px solid #213805;
  
    svg {
      width: 70%;
      height: 70%;
      color: #213805;
    }

    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
  
      padding: 1rem;
  
      width: 80%;
      height: 100vh;
  
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
  
      //background: url('../../assets/bgWhite.png');
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
  
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
  
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
  
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
  
        @media screen and (min-width:  1160px) {
          display: none;
        }
      }
  
      @media screen and (min-width: 1160px) {
        display: none;
      }
    }
    @media screen and (min-width: 1160px) {
      display: none;
    }
  }