/* ================================================
|   |   |   |   Brands
================================================ */
// #brands {
//     background: url('../../assets/dayton.jpg') fixed center/95%;

// }

// #brands .content-box-md-brands {
//     background: rgba(0,0,0, 0.75);
//   }

#brands .horizontal-heading {
    /*margin-bottom: 30px;*/
  }
  
#clients-list {
padding: 0 0 0 0;
}

.client {
// padding: 0 10px;
// max-width: 150px;
// margin: 0 auto;
// line-height: 100px;
}

.client img {
display: inline-block !important;
}

.brand-bold {
// color: #9fd399;
font-weight: 700 !important;

color: #fff;
font-weight: 600;
text-shadow: 15px 10px 0 #000;
-webkit-text-fill-color: #fff;
-webkit-text-stroke: 1px #9fd399;
}

.brand-thin {
font-weight: 100;
font-size: 45px !important;

color: #13C501;
font-weight: 600;
text-shadow: 15px 10px 0 #000;
-webkit-text-fill-color: #9fd399;
}






















/* Cards */


.category-name {
    font-family: sans-serif;
    width: -webkit-fill-available;
    text-align: center;
    font-size: 40px;
}

.card-category-2 ul, .card-category-3 ul, .card-category-4 ul, .card-category-5 ul  .card-category-6 ul {
    padding: 0;
}
    
.card-category-2 ul li, .card-category-3 ul li, .card-category-4 ul li, .card-category-5 ul li, .card-category-6 ul li {
    list-style-type: none;
    display: inline-block;
    vertical-align: top;
}

.card-category-2 ul li, .card-category-3 ul li {
    margin: 10px 5px;
}

.card-category-1, .card-category-2, .card-category-3, .card-category-4, .card-category-5, .card-category-6 {
    font-family: sans-serif;
    margin-bottom: 45px;
    text-align: center;
}
.card-category-1 div, .card-category-2 div {
    display:inline-block;
}

.card-category-1 > div, .card-category-2 > div:not(:last-child) {
    margin: 10px 5px;
    text-align: left;
}

/* Basic Card */
.basic-card {
    width:300px;
    position: relative;
    
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    -o-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    // box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.basic-card .card-content {
    padding: 30px;
}

.basic-card .card-title {
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
}

.basic-card .card-text {
    line-height: 1.6;
}

.basic-card .card-link {
    padding: 25px;
    width: -webkit-fill-available;
}

.basic-card .card-link-b {
    padding: 25px;
    width: -webkit-fill-available;
}

.basic-card .card-link a {
    text-decoration: none;
    position: relative;
    padding: 10px 0px;
}

.basic-card .card-link-b a {
    text-decoration: none;
    position: relative;
    padding: 10px 0px;
}

.basic-card .card-link a:after {
    top: 30px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    width: 0;

    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.basic-card .card-link-b a:after {
    top: 30px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    width: 0;

    -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.basic-card .card-link a:hover:after { 
    width: 100%; 
    left: 0; 
}

.basic-card .card-link-b a:hover:after { 
    width: 100%; 
    left: 0; 
}


.basic-card-aqua {
    background-image: linear-gradient(to bottom right, #00bfad, #99a3d4);
    background-image: linear-gradient(to bottom right, #fff, #fff);

    background-color: #fff;

    :hover {
        // background-image: linear-gradient(to bottom right, #4dd0e1, #9fd399);
        cursor: pointer;

    }

    .card-content:hover {
        background-image: linear-gradient(to bottom right, #fff, #9fd399);
    }
    
    .card-link:hover {
        background-image: linear-gradient(to bottom right, #4dd0e1, #9fd399);
    }

    .card-link-b:hover {
        background-image: linear-gradient(to bottom right, #4dd0e1, #9fd399);
    }

    .card-link a {
        color: #000;
        font-weight: 500;
    }

    .card-link-b a {
        color: #000;
        font-weight: 500;
    }

    .card-link:hover a {
        color: #213705;
        font-weight: 500;
    }

    .card-link-b:hover a {
        color: #213705;
        font-weight: 500;
    }

}

.basic-card-aqua {

    .card-content {
        height: 300px;
    }
}

.basic-card-aqua .card-content, .basic-card .card-link a,
.basic-card-aqua .card-content, .basic-card .card-link-b a {
    
    text-align: center;


}

.basic-card-aqua .card-link {
    border-top:1px solid #000;
    text-align: center;
    background-color: #dcdcdc;
    // margin: 30px auto;
}

.basic-card-aqua .card-link-b {
    border-top:1px solid #000;
    text-align: center;
    background-color: #fff;
    // margin: 30px auto;
}

        // .basic-card-aqua .card-link a:after {
        //     background:#000;
        // }

.basic-card-lips {
    background-image: linear-gradient(to bottom right, #ec407b, #ff7d94);
}

.basic-card-lips .card-content {
    color: #fff;
}

.basic-card-lips .card-link {
    border-top: 1px solid #ff97ba;
}

.basic-card-lips .card-link a:after {
    background:#fff;
}

.basic-card-light {
    border: 1px solid #eee;
}

.basic-card-light .card-title, .basic-card-light .card-link a {
    color: #636363;
}

.basic-card-light .card-text {
    color: #7b7b7b;
}

.basic-card-light .card-link {
    border-top: 1px solid #eee;
}

.basic-card-light .card-link a:after {
    background:#636363;
}

.basic-card-dark {
    background-image: linear-gradient(to bottom right, #252525, #4a4a4a);
}

.basic-card-dark .card-title, .basic-card-dark .card-link a {
    color:#eee;
}

.basic-card-dark .card-text {
    color: #dcdcdcdd;
}

.basic-card-dark .card-link {
    border-top: 1px solid #636363;
}

.basic-card-dark .card-link a:after {
    background:#eee;
}



.influencer-description {
      /*limit text*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}