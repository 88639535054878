.carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
  }
  
  .carousel .slide {
    background: #fff !important;
    color: black;
    height: 100%;
  }
  
  .carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
  }

  .testimonial_styles {
    height: 370px;
    margin-top: 6%;
  }

  
  .myCarousel {
    // background: #213705;
    // background: #000;
    // margin-top: -6%;
    margin-top: 6%;
    // width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 286px;
    background: url("../../assets/dayton4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 40%;
    // width: 100%;
  }

  .content-box-md-brands .myCarousel {
    // background: rgba(0,0,0,0.8);
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
  }
  
  .myCarousel h3 {
    color: #fff;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
  }
  
  .myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #fff;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #fff;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #fff;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 45% !important;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      // background: #fafafa;
    //   margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px;
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }


  .circular--square { display: inline-block; position: relative;  overflow: hidden; border-radius: 50%; } .circular--landscape img { width: auto; height: 100%; margin-left: -50px; }


  #testimonials {
    /*background: url("../img/kt_about/Influence_is_power.png") no-repeat fixed center;*/
    background: url("../../assets/dayton.jpg") no-repeat fixed center;
    background-size: cover;
  }
  
  #testimonials .content-box-md-brands {
    background: rgba(0,0,0,0.8);
  }