/* ================================
| | |  Mobile Menu
================================ */

.navbar-header {
    position: relative;
}

#mobile-nav-open-btn {
    font-size: 30px;
    color: #cd0000;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 2px;
    /* hide mobile nav open btn */
    display: none;
}

#mobile-nav {
    /* by default, mobile nav will be hidden with height 0% */
    height: 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(220, 220, 220, 0.9);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    overflow-y: hidden;
}

#mobile-nav-close-btn {
    font-family: "Raleway", sans-serif;
    font-size: 70px;
    color: #454545;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    top: -16px;
    right: 13px;
    -webkit-transition: .3s;
    transition: .3s;
}

#mobile-nav-content {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    position: relative;
    top: 10%;
}

#mobile-nav ul li {
    margin-bottom: 20px;
}

#mobile-nav a {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #454545;
    font-weight: 400;
    text-transform: uppercase;
    display: inline;
    -webkit-transition: .3s;
    transition: .3s;
}

#mobile-nav a:hover,
#mobile-nav a:focus,
#mobile-nav-close-btn:hover,
#mobile-nav-close-btn:focus {
    color: #cd0000;
    background: none;
}

/* Mobile nav scroll spy active state */

.white-nav-top #mobile-nav ul.nav>li.active>a {
    color: #cd0000;
    font-weight: 500;
}

/* ================================================
|   |   |   |   Bootstrap Media Queries
================================================ */

/* Large Devices (Desktops & Laptops) */
@media (min-width: 1200px) {

    /* Navigation */
    .site-nav-wrapper {
        padding: 0 20px;
    }

    /* Start of Contact Overlay - Team Member Info 1 & 2 */
    .team-member-info {
        width: 100%;
        height: 100%;
        padding: 100px 0;
        margin-top: 20px;
    }

    .team-member-info-2 {
        width: 100%;
        height: 100%;
        padding: 50px 0;
        margin-top: 20px;
    }

    .team-member-info-3 {
        width: 100%;
        height: 100%;
        padding: 80px 0;
        margin-top: 20px;
    }

    .team-member-info span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }
    .team-member-info-2 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 24px auto 5px auto;
    }
    .team-member-info-3 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }
    .team-member-info-2 h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }
    .team-member-info-3 h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }

    .team-member-info p {
        font-size: 18px;
        line-height: 25px;
    }
    .team-member-info-2 p {
        font-size: 18px;
        line-height: 25px;
    }
    .team-member-info-3 p {
        font-size: 18px;
        line-height: 25px;
    }

    .team-member-info p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-2 p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-3 p {
        color: #dcdcdc;
        margin: 0;
    }

    .social-text-m {
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    #team .social-list {
        /*margin-top: 10px;*/
        font-size: 1.8rem;
    }

    #stats .social-list {
        margin-top: 20px;
        font-size: 1.8rem;
    }

    #services .social-list {
        font-size: 1.8rem;
        margin-top: 20px;
    }
    /* End of Contact Overlay - Team Member Info 1 & 2 */

}

/* Medium Devices (Landscape Tablets & Medium Desktops) */
@media (min-width: 992px) and (max-width: 1199px) {

    /* Buttons*/
    .btn-client-book {
        padding: 12px 30px 12px 30px;
        font-size: 13px;
        /*font-weight: bold;*/
    }

    .btn-social-i {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 12px 6px 12px;
        font-size: 22px;
    }


    .btn-social-i-f {
        padding: 6px 12px 6px 12px;
        font-size: 22px;
    }

    .btn-social-i-t {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 11px 6px 11px;
        font-size: 22px;
    }

    .btn-social-i-y {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 11px 6px 11px;
        font-size: 22px;
    }
    /* End of Buttons*/


    .site-nav-wrapper {
        padding: 0;
    }

    .vertical-heading h2 {
        font-size: 29px;
    }

    #services-tabs .tab-bg {
        padding: 16px 30px;
        min-width: 300px;
        min-height: 273px;
    }

    .service-tab h3 {
        margin-bottom: 0;
    }

    .service-tab h2 {
        margin-bottom: 10px;
    }

    .service-tab p {
        margin-bottom: 10px;
        line-height: 20px;
    }

    #map {
        height: 500px;
    }

    /* Start of Contact Overlay - Team Member Info 1 & 2 */
    .team-member-info {
        width: 100%;
        height: 100%;
        padding: 100px 0;
        margin-top: 20px;
    }

    .team-member-info-2 {
        width: 100%;
        height: 100%;
        padding: 50px 0;
        margin-top: 20px;
    }

    .team-member-info-3 {
        width: 100%;
        height: 100%;
        padding: 80px 0;
        margin-top: 20px;
    }

    .team-member-info span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }
    .team-member-info-2 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 24px auto 5px auto;
    }
    .team-member-info-3 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }
    .team-member-info-2 h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }
    .team-member-info-3 h6 {
        color: #dcdcdc;
        font-size: 22px;
        margin: 0;
    }

    .team-member-info p {
        font-size: 18px;
        line-height: 25px;
    }
    .team-member-info-2 p {
        font-size: 18px;
        line-height: 25px;
    }
    .team-member-info-3 p {
        font-size: 18px;
        line-height: 25px;
    }

    .team-member-info p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-2 p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-3 p {
        color: #dcdcdc;
        margin: 0;
    }

    .social-text-m {
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    #team .social-list {
        /*margin-top: 10px;*/
        font-size: 1.8rem;
    }

    #stats .social-list {
        margin-top: 20px;
        font-size: 1.8rem;
    }

    #services .social-list {
        font-size: 1.8rem;
        margin-top: 20px;
    }
    /* End of Contact Overlay - Team Member Info 1 & 2 */

}





/* Small Devices (Landscape Tablets & Medium Desktops) */
@media (min-width: 768px) and (max-width: 991px) {

    .videoWidth {
    max-width: 100%;
    }


    .navbar-nav {
        display: none;
    }

    #mobile-nav-open-btn {
        display: block;
        position: fixed;
        margin-right: 18px;
        margin-top: 10px;
    }

    /* Navigation */
    .site-nav-wrapper {
        padding: 0;
    }

    /* Home */
    .home-heading h1 {
        font-size: 41px;
    }

    .home-text p {
        font-size: 15px;
        font-weight: 600;
    }

    /* Generic */
    .btn-general {
        padding: 8px 35px 8px 35px;
        font-size: 10px;
    }

    .btn-client-book {
        padding: 12px 30px 12px 30px;
        font-size: 13px;
        /*font-weight: bold;*/
    }

    .btn-social-i {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 12px 6px 12px;
        font-size: 22px;
    }


    .btn-social-i-f {
        padding: 6px 16px 6px 16px;
        font-size: 22px;
    }

    .btn-social-i-t {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 11px 6px 11px;
        font-size: 22px;
    }

    .btn-social-i-y {
        /*padding: 9px 12px 9px 12px;*/
        padding: 6px 11px 6px 11px;
        font-size: 22px;
    }

    /* Generic */
    .vertical-heading h2 {
        font-size: 29px;
    }

    .nav > li > a {
        padding: 10px 10px;
        margin-top: 10px;
    }

    .about-h {
        min-height: 403px !important;
    }

    /* Tech Statement */
    .tech-statement h3 {
        font-size: 18px;
        line-height: 30px;
    }

    /* Services 02 */
    .r-tabs-accordion-title {
        text-align: center;
        margin-bottom: 10px;
    }

    .r-tabs-accordion-title a {
        font-family: "Raleway", sans-serif;
        font-size: 13px;
        color: #454545;
        text-transform: uppercase;
        font-weight: 400;
        padding: 0;
        text-decoration: none;
    }

    .r-tabs-accordion-title.r-tabs-accordion-active a {
        color: #cd0000;
        font-weight: 700;
        border-bottom: 3px solid #cd0000;
        padding-bottom: 5px;
        -webkit-transition: border-color 300ms linear;
        transition: border-color 300ms linear;
    }

    /* Stats Icon Margins */

    .stats-item-t i {
        color: #38A1F3;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-y i {
        color: #cd0000;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-f i {
        color: #4267b2;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-i i {
        color: #F56040;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    /* Stats */
    .stats-item p {
        line-height: 18px;
    }

    /*  Counter size  */
    .stats-item h3 {
        font-size: 18px;
        color: #dcdcdc;
        margin: 5px 0 5px 0;
        font-weight: 700;
    }

    /*  Contact Form Size  */
    .content-box-contact {
        padding: 30px 0 60px;
    }

    #offices {
        margin-left: 2px;
    }
    .social-list {
        margin-left: 5px;
    }

    /* Start of Contact Overlay - Team Member Info 1 & 2 */
    .team-member-info {
        width: 100%;
        height: 100%;
        padding: 40px 0;
        margin-top: 20px;
    }

    .team-member-info-2 {
        width: 100%;
        height: 100%;
        padding: 10px 0;
        margin-top: 20px;
    }


    .team-member-info-3 {
        width: 100%;
        height: 100%;
        padding: 40px 0;
        margin-top: 20px;
    }

    .team-member-info span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info-2 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info-3 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info h6 {
        color: #dcdcdc;
        font-size: 18px;
        margin: 0;
    }

    .team-member-info-2 h6 {
        color: #dcdcdc;
        font-size: 18px;
        margin: 0;
    }

    .team-member-info-3 h6 {
        color: #dcdcdc;
        font-size: 18px;
        margin: 0;
    }

    .team-member-info p {
        font-size: 16px;
        line-height: 25px;
    }

    .team-member-info-2 p {
        font-size: 16px;
        line-height: 25px;
    }

    .team-member-info-3 p {
        font-size: 16px;
        line-height: 25px;
    }


    .team-member-info p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-2 p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-3 p {
        color: #dcdcdc;
        margin: 0;
    }

    .social-text-m {
        line-height: 15px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    #team .social-text-m {
        padding-top: 0;
        padding-bottom: 0;
    }

    #team .social-list {
        font-size: 1.8rem;
        /*margin-top: 10px;*/
    }

    #stats .social-list {
        font-size: 1.8rem;
        margin-top: 20px;
    }
    /* End of Contact Overlay - Team Member Info 1 & 2 */


    .office {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .about-text {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/* Extra Small Devices (Desktop & Laptops) */
@media(max-width: 767px) {


    .videoWidth {
        max-width: 100%;
        }

    .arrow-down {
        display: none;
    }

    /* Navigation */
    .navbar {
        padding: 20px 0;
    }

    .navbar-brand {
        margin-left: -15px;
    }

    .site-nav-wrapper,
    .white-nav-top {
        padding: 0;
    }

    #mobile-nav-open-btn {
        display: block;
    }

    /* Home Section */

    .home-heading h1 {
        font-size: 40px;
    }

    .home-text p {
        font-size: 14px;
        font-weight: 600;
    }

    .btn-general {
        padding: 8px 35px 8px 35px;
        font-size: 10px;
    }

    .btn-client-book {
        padding: 8px 22px 8px 22px;
        font-size: 11px;
        font-weight: bold;
    }

    .btn-social-i {
        /*padding: 9px 12px 9px 12px;*/
        padding: 7px 11px 7px 11px;
        font-size: 15px;
        margin: 10px;
    }


    .btn-social-i-f {
        padding: 7px 13px 7px 13px;
        font-size: 15px;
        margin: 10px;
    }

    .btn-social-i-t {
        /*padding: 9px 12px 9px 12px;*/
        padding: 7px 10px 7px 10px;
        font-size: 15px;
        margin: 10px;
    }

    .btn-social-i-y {
        /*padding: 9px 12px 9px 12px;*/
        padding: 7px 10px 7px 10px;
        font-size: 15px;
        margin: 10px;
    }

    .btn-social-i-tg {
        padding: 5px 10px 5px 10px;
        font-size: 11px;
    }

    /* About Section */

    .content-box-main {
        margin-top: -80px;
    }

    #about .about-right {
        margin-top: 60px;
    }

    .vertical-heading {
        text-align: center;
    }

    .vertical-heading h5 {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        display: block;
        position: static;
        margin-bottom: 8px;
        word-spacing: 3px;
        font-weight: 700;
    }

    .content-box-main {
        margin-top: 10px;
    }

    /* About Right */
    .about-item p {
        font-size: 18px;
        font-weight: 300;
        line-height: 35px;
    }

    #about .container .about-right {
        padding-left: 80px;
        padding-right: 80px;
    }

    #about .vertical-heading h5 {
        top: 200px;
    }

    #about .vertical-heading h2 {
        margin-top: 0;
    }

    .vertical-heading h2 {
        font-size: 29px;
        line-height: normal;
        margin: 0;
    }

    .about-item {
        padding:  55px 30px;
        margin-bottom: 35px;
    }

    .about-item i {
        font-size: 30px;
    }

    .about-item h3 {
        font-size: 18px;
    }

    /* Team */
    .content-box-team {
        padding: 20px 0 20px;
    }

    .team-left .vertical-heading {
        min-height: 100px;
    }

    #team .vertical-heading h5 {

    }

    #team .vertical-heading h2 {
        margin-top: 0;
    }

    /* Statement */
    #statement h3 {
        font-size: 24px;
        line-height: 30px;
    }

    #statement .content-box-lg {
        padding: 100px 0;
    }

    /* Clients */
    #portfolio .vertical-heading {
        margin-top: 40px;
    }

    #portfolio .vertical-heading h5 {
        font-weight: bold;
    }

    /* Productions */

    /* Horizontal headings */
    .horizontal-heading {
        margin-bottom: 40px;
    }

    .horizontal-heading {
        font-size: 30px;
    }

    /* Services 02 */
    .r-tabs-accordion-title {
        text-align: center;
        margin-bottom: 10px;
    }

    .r-tabs-accordion-title a {
        font-family: "Raleway", sans-serif;
        font-size: 13px;
        color: #212226;
        text-transform: uppercase;
        font-weight: 400;
        padding: 0;
        text-decoration: none;
    }
    /* Tab active state */
    .r-tabs-accordion-title.r-tabs-state-active a {
        color: #cd0000;
        font-weight: 700;
        border-bottom: 3px solid #cd0000;
        padding-bottom: 5px;
        -webkit-transition: border-color 300ms linear;
        transition: border-color 300ms linear;
    }

    #services-tabs .tab-bg {
        text-align: center;
        padding: 40px 30px;
    }

    #services-tabs h2 {
        font-size: 32px;
    }

    #services-tabs h3 {
        font-size: 22px;
    }

    #services-tabs p {
        font-size: 18px;
        line-height: 30px;
    }

    /* Stats */

    #stats .vertical-heading h5 {
        font-weight: bold;
        margin-bottom: 30px;
        word-spacing: 3px;
        font-size: 1.2em;
    }


    .stats-item {
        margin-bottom: 30px;
        padding: 25px 5px;
    }

    /*.stats-item i {*/
    /*    font-size: 30px;*/
    /*}*/


    .stats-item h3 {
        font-size: 24px;
    }

    .social-links-margin {
        margin-bottom: 0;
    }

    /* Stats Icon Margins */

    .stats-item-t i {
        color: #38A1F3;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-y i {
        color: #cd0000;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-f i {
        color: #4267b2;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    .stats-item-i i {
        color: #F56040;
        background: #dcdcdc;
        padding: 10px;
        border-radius: 50%;
    }

    /* Stats */
    .stats-item p {
        line-height: 18px;
    }

    /*  Counter size  */
    .stats-item h3 {
        font-size: 18px;
        color: #dcdcdc;
        margin: 5px 0 5px 0;
        font-weight: 700;
    }


    /*  Contact  */
    .content-box-contact {
        padding: 30px 0 60px;
    }

    #contact-left {
        text-align: center;
    }

    #contact .vertical-heading h5 {
        /*margin-right: 10px;*/
        margin-left: -1px;
    }

    #contact .vertical-heading h2 {
        /*margin-right: 10px;*/
        margin-bottom: 10px;
        margin-left: -4px;
    }

    .office {
        margin-bottom: 20px;
        margin-right: -22px;
        margin-top: 10px;
    }

    ul.office-details li {
        display: block;
        margin-top: 25px;
        font-size: 1.2em;
    }

    ul.office-details li i {
        width: auto;
        display: block;
        margin-bottom: 3px;
        font-size: 1.2em;
    }

    #contact-left ul.social-list {
        margin: 20px 40px 20px 48px;
        font-size: 3rem;
    }

    #contact-left ul.social-list i {
        font-size: 3rem;
    }

    .instagram-icon-color {

    }

    .contact-icon-s {
        position: relative;
    }

    .contact-icon-m {
        position: absolute;
        top: 18px;
    }

    .insta-c-i {
        margin-left: -13px;
    }

    .face-c-i {
        margin-left: -9px;
    }

    #contact-left ul.social-list li a {
        color: #dcdcdc;
        padding: 30px;
        border: 2px solid #dcdcdc;
    }

    /* Contact Right */
    .form-t-c {
        text-align: center;
    }

    .form-t-c h4  {
        font-size: 18px;
    }

    .form-p-c {
        text-align: center;
    }
    #contact form p {
        font-size: 14px;
    }

    .form-submit-b {
        text-align: center;
    }

    /* Start of Contact Overlay - Team Member Info 1 & 2 */
    .team-member-info {
        width: 100%;
        height: 100%;
        padding: 80px 0;
        margin-top: 20px;
    }

    .team-member-info-2 {
        width: 100%;
        height: 100%;
        padding: 60px 0;
        margin-top: 20px;
    }


    .team-member-info-3 {
        width: 100%;
        height: 100%;
        padding: 80px 0;
        margin-top: 20px;
    }

    .team-member-info span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info-2 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 24px auto 5px auto;
    }

    .team-member-info-3 span {
        display: inline-block;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        height: 6px;
        width: 30px;
        margin: 14px auto 5px auto;
    }

    .team-member-info h6 {
        color: #dcdcdc;
        font-size: 24px;
        margin: 0;
    }

    .team-member-info-2 h6 {
        color: #dcdcdc;
        font-size: 24px;
        margin: 0;
    }

    .team-member-info-3 h6 {
        color: #dcdcdc;
        font-size: 24px;
        margin: 0;
    }

    .team-member-info p {
        font-size: 18px;
        line-height: 25px;
    }

    .team-member-info-2 p {
        font-size: 18px;
        line-height: 25px;
    }

    .team-member-info-3 p {
        font-size: 18px;
        line-height: 25px;
    }


    .team-member-info p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-2 p {
        color: #dcdcdc;
        margin: 0;
    }
    .team-member-info-3 p {
        color: #dcdcdc;
        margin: 0;
    }

    .social-text-m {
        line-height: 15px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    #team .social-list {
        font-size: 2.8rem;
        /*margin-top: 10px;*/
    }

    #stats .social-list {
        font-size: 2.8rem;
        margin-top: 20px;
    }
    /* End of Contact Overlay - Team Member Info 1 & 2 */

    #services .social-list {
        font-size: 2.8rem;
        margin-top: 20px;
    }

    /* Mobile Navigation */
    .mobile-nav-top {
        padding: 10px 0;
    }

    .about-text {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media(max-width: 767px) {

    #about {
        padding-top: 0 !important;
    }

    .col-mobile {
        padding-bottom: 60px;
    }

    .about-text {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .col-mobile-top {
        margin-top: 20px;
    }

    #about .about-right {
        margin-top: 40px !important;
    }

    .homepage-heading-display {
        display: none;
    }

    /* Navigation */
    .navbar-brand {
        margin-left: -30px;
    }

    /* Home */

    .home-heading {
        font-size: 25px;
    }

    .home-text p {
        font-size: 14px;
        line-height: 16px;
        margin: 8px 10px 25px 10px;
        font-weight: 600;
    }



    /* About */
    #about .container .about-right {
        padding-left: 15px;
        padding-right: 15px;
    }

    #about .container .about-right p {
        text-align: center;
    }

    .about-text {
        font-size: 14px;
    }

    /* Team */
    #team .vertical-heading {
        margin-bottom: 0;
    }

    /* Statement */
    #statement h3 {
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
    }

    #statement .content-box-lg {
        padding: 60px 0;
    }

    /* Clients */
    #portfolio .vertical-heading {
        margin-top: 30px;
    }

    /* Production */
    #services-tabs h2 {
        font-size: 24px;
    }

    #services-tabs h3 {
        font-size: 18px;
    }

    #services-tabs p {
        font-size: 13px;
        line-height: 30px;
    }

    #services-tabs .tab-bg {
        padding: 0 30px 20px;
    }

    #services-tabs h2 {
        margin-bottom: 10px;
        margin-top: 0;
    }

    /* Generic */
    .vertical-heading h2 {
        font-size: 22px;
    }

    .isotope-filters {
        text-align: center;
    }

    .isotope-filters button {
        font-size: 10px;
        padding-right: 14px;
    }


}