/* ================================================
|   |   |   |   About 01
================================================ */

.main-about h2 {
    -webkit-text-stroke: 1px #213705;
    color: #9fd399;
    font-weight: 700!important;
}

.about__image-section {
    @media(max-width: 767px) {
      display: none;
    }
  }


#about .vertical-heading h2 {
    color: #454545;
    margin-top: 90px;
  }
  
  #about .about-right {
    margin-top: 60px;
  }
  
  #about .about-left {
  
  }
  
  #about .vertical-heading h5 {
    color: #4dd0e1;
    top: 150px;
    word-spacing: 3px;
    margin-top: 35px;
  }
  
  .image-main {
    width: 234px;
    height: 234px;
  }
  
  .about-main:hover .about-overlay {
    opacity: 1;
  }
  
  .about-main {
    position: relative;
  }
  
  .image-main {
    border: 2px solid #454545;
    border-radius: 50%;
  }
  
  
  
  .about-overlay {
    background-color: rgba(69, 69, 69, .9);
    position: absolute;
    top: 0;
    left: 0;
    width: 234px;
    height: 234px;
    margin: 0 auto;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    border-radius: 50% !important;
  }
  
  
  .about-text {
    font-size: 17px;
    text-align: center;
  }
  
  .about-text-bold {
    /*color: #cd0000;*/
    /*font-weight: 700;*/
  }
  
  .about-right p:first-child {
    margin-bottom: 30px;
  }
  
  .about-bottom {
    margin-top: 5px;
  }
  
  .about-bottom img {
    margin: 0 auto;
  }
  
  /* ================================================
  |   |   |   |   About 02
  ================================================ */
  
  .about-02 {
    background-color: #a1a0a1;
  }
  
  .about-h {
    min-height: 331px !important;
  }
  
  .about-item {
    background-color: #dcdcdc;
    padding: 40px 30px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.1);
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    color: #000;
  }
  
  .about-item .react-icon-about {
    font-size: 42px;
    margin: 0;
  }
  
  .about-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .about-item hr {
    width: 45px;
    height: 3px;
    background-color: #000;
    margin: 0 auto;
    border: none;

    
  }
  
  .about-item p {
    margin-top: 20px;
  }
  
  /* Hover state */
  
  .about-item:hover {
    background-color: #b5d6b1;
    cursor: pointer;
  }
  
  .about-item:hover i,
  .about-item:hover .react-icon-about,
  .about-item:hover h3,
  .about-item:hover p {
    // color: #dcdcdc;

    color: #000;
    
  }
  
  .about-item:hover hr {
    // background-color: #dcdcdc;
    background-color: #000;
  }
  
  .about-item:hover i,
  .about-item:hover .react-icon-about {
    transform: translateY(-5px);
  }
  
  /* Smooth transition */
  .about-item,
  .about-item i,
  .about-item .react-icon-about,
  .about-item hr {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
  }






  .content-box-md-interact {
    padding: 0 0 0 0;
    /*background-color: #F4F4F4;*/
    border-bottom: 2px solid #46494C;
}
  
  .map-color-3 {
    background-color: #F4F4F4;
    padding-top: 0;
    border-bottom: 2px solid #46494C;
    border-top: 2px solid #46494C;
    z-index: 5;
  }
  
  .home-headings {
    text-align: center;
  }
  
  .home-headings h1 {
    color: #11151C;
    //font-size: 40px;
    font-weight: 200;
    /*text-transform: uppercase;*/
    //margin: 0 0 30px 0;
    display: inline-block;
  }
  
  .home-headings h1 span {
    color: #223903;
    font-weight: 500;
    //font-size: 40px;
    // text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  
  }
  
  .tools-p-align {
    padding-bottom: -10px;
  }
  
  .influencer-name {
    margin-bottom: 2px; margin-top: -10px;
  }
  
  .influencer-name-h1 {
    margin-bottom: 2px; padding: 0;
  }
  
  .influencer-name-span {
    font-size: 20px; padding: 0;
  }
  
  .client-section-b {
    background: none;
  }
  
  .map-color-6 {
    background-color: #DCDCDD;
    padding: 0 0 20px 0;
  }
  
  #examples-2 {
    font-size: 0;
    display: block;
    list-style: none;
    margin: 40px 40px 0 40px;
    padding: 0;
    text-align: center;
  }
  
  #examples-2 a {
    border: 1.5px solid #950000;
    color: #F4F4F4;
    font-size: 14px;
    display: block;
    padding: 12px;
    margin: 0 8px;
    transition: border-color 0.2s, color 0.2s;
    text-decoration: none;
  }
  
  #examples-2 li {
    display: inline-block;
    margin-bottom: 6px;
  }
  
  .background-map-2 {
    background: #cd0000;
    border-radius: 5px;
  }
  
  .influencer-image {
    margin: 30px auto 0; float: none;
  }
  
  .influencer-image-2 {
    width:100%;
  }
  
  .column-show {
    //float: left;
    // width: 50%;
    padding: 5px;
  
    @media(max-width: 767px) {
      width: 100%;
    }
  }
  
  .map-color-7 {
    background-color: #F4F4F4;
    border: 2px solid #46494C;
    border-radius: 5px;
    //margin-top: 40px;
    //margin-bottom: 60px;
    margin-top: 12px;
    //margin-bottom: 60px;
    margin-right: 6px;
    margin-left: 6px;
  
    @media(max-width: 767px) {
      margin-bottom: 0;
      margin-top: 12px;
      margin-left: 0;
    }
  }
  
  .influencer-info {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  
    @media(max-width: 767px) {
      width: 100%;
    }
  }
  
  .map-paragraph {
    padding: 20px;
  }
  
  .i-map-paragraph {
    text-align: center;
  }
  
  .template-p-detail {
    text-align: center;
    line-height: 1.5em;
    font-size: 16px;
    padding-right: 20px;
    padding-left: 20px;
    width: 65%;
    margin: 0 auto;
  
    @media(max-width: 767px) {
      width: 100%;
    }
  }
  
  .show-page-image-s {
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 767px) {
        display: block;
      }
  }
  
  .show-break-style {
    width: 3%;
    margin: 20px auto;
    border: 2px solid #000;
  }
  
  
  
  // Services
  
  .content-box-md {
  //padding: 60px 0 80px 0;
    padding: 20px 0 80px 0;
    background-color: #DCDCDD;
  }
  
  .horizontal-heading h5 {
    font-size: 16px;
    color: #223903;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .horizontal-heading h2, .horizontal-heading h2 {
    font-size: 48px;
    font-weight: 200;
    line-height: 45px;

    -webkit-text-stroke: 1px #213705;
    color: #9fd399;
    font-weight: 700!important;
  }
  
  .services-button-s {
    border: 1px solid;
  }
  
  .pricing-table {
    //-webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    //box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 20px 21px;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
  }
  
  .pricing-table.black {
    background: #F4F4F4;
    color: #46494C;
    border: 1px solid #223903;
    text-align: center;
  }
  
  .pricing-table.black .type h4 {
    color: #223903;
  }
  .pricing-table .type h4 {
    color: #f4c613;
    //font-size: 18px;
    /* margin-bottom: 15px; */
  }
  
  .pricing-table .price {
    //border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 24px;
    margin-bottom: 16px;
  }
  
  .pricing-table .price h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 42px;
    font-weight: 100;
    /* margin: 0 0 0 24px; */
    margin: 0 0 0 0;
    position: relative;
  }
  
  .pricing-table .price h2 span.dollar {
    font-size: 18px;
    font-weight: 300;
    position: absolute;
    /* left: -20px; */
    left: 85px;
    top: 5px;
  }
  .pricing-table .price h2 span {
    font-family: "Raleway", sans-serif;
  }
  
  .pricing-table .price h2 span.month {
    font-size: 11px;
    font-weight: 400;
    position: absolute;
    /* left: 5px; */
    left: 108px;
    top: -14px;
  }
  
  .pricing-table ul.package {
    list-style: none;
    padding: 0 0;
  }
  
  .pricing-table ul.package li {
    font-family: "Open Sans", sans-serif;
    //font-size: 13px;
    font-size: 15px;
    font-weight: 400;
    line-height: 40px;
  }
  
  .pricing-table ul.package li i {
    width: 24px;
  }
  
  .branding-promo-s {
    padding: 20px;
  }

  #home-4 {
    //background: none;
    /* height: 69px; */
    // background-color: #000000cc;
    background-color: rgba(0,0,0,0.8);
    height: 94px;
  }
  
  #home-bg-video-2 {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    //background: url(../video/websitewarehouse6.jpg) no-repeat;
    background-size: cover;
  }
  
  #home-overlay-2 {
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .email__design-s {
    color: #500000;
    text-decoration: none;
    font-weight: 800;
  }

// Influencer Overlay

.influencer-item-img {
    position: relative;
  }
  
  .btn-social-img {
    color: white;
    background: none;
    border: 1px solid #F4F4F4;
  }
  
  .btn-social-influencer-img {
    font-family: 'raleway', sans-serif;
    border-radius: 28px;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0 5px;
    transition: all .5s;
    padding: 2px 10px 5px 10px;
  }
  
  .btn-social-instagram:hover,
  .btn-social-instagram:focus {
    color: white;
    background:
  
            radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
            radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  
            radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
            radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  
            radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
            radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  
            radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  
            linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    /*border: 1px solid rgb(82, 88, 207);*/
    border: 1px solid #f4f4f4;
  }
  
  .btn-social-facebook:hover,
  .btn-social-facebook:focus {
    color: white;
    background: #3b5998;
  }
  
  .btn-social-twitter:hover,
  .btn-social-twitter:focus {
    color: white;
    background: #00aced;
  }
  
  .btn-social-youtube:hover,
  .btn-social-youtube:focus {
    color: white;
    background: #FF0000;
  }
  
  .btn-social-tiktok:hover,
  .btn-social-tiktok:focus {
    color: white;
    background: #5bdcd2;
  }
